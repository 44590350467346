.results {
    width: 100%;
    border-collapse: collapse;
  }
  
  .results th {
    font-size: 10px;
    text-transform: uppercase;
  }
  
  .results th,
  .results td {
    padding: 8px;
  }
  
  .results tbody tr {
      opacity: 0;
  }
  
  .clamped {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  